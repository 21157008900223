import React from 'react'
import { Card } from 'antd'
import { UserOutlined, RightOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'

const OtherJobs = ({ jobs }) => {
  const navigate = useNavigate()

  return (
    <div className='other'>
      <h3 className='other__title'>ตำแหน่งงานอื่น ๆ</h3>

      <div className='underline'></div>

      <div className='other__container'>
        {jobs.map((item, index) => {
          let image = require(`../images/jobs/${item.image}`)

          return (
            index < 4 && (
              <Card
                key={index}
                hoverable
                style={{ width: '100%' }}
                cover={<img alt='ร่วมเป็นทีมเดียวกัน' width='auto' height='auto' src={image} />}
                className='news__card'
                onClick={() => navigate(`/jobs/${item.id}`)}
              >
                <h4>{item.title}</h4>
                <div className='other-price'>
                  <UserOutlined style={{ color: '#006d68', marginRight: 10 }} />
                  <h5>จำนวน {item.number} อัตรา</h5>
                </div>
              </Card>
            )
          )
        })}
      </div>

      <div className='see-all'>
        <div>
          <Link to='/jobs'>ดูทั้งหมด</Link>
          <RightOutlined style={{ color: '#006d68', marginLeft: 10 }} />
        </div>
      </div>
    </div>
  )
}

export default OtherJobs
