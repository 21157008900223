import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Layout from '../components/Layout'
import { Breadcrumb, Card, Pagination } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const Jobs = () => {
  const navigate = useNavigate()

  const [jobs, setJobs] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/jobs`)
      .then(async response => {
        // console.log(response.data)
        const { code, item, message } = await response.data

        if (code === 10) {
          setJobs(item)
        } else {
          console.log(message)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>ร่วมเป็นทีมเดียวกัน | โรงพยาบาลจริยธรรมรวมแพทย์กระบี่</title>
        <meta
          name='description'
          content='ร่วมเป็นทีมเดียวกัน กับ โรงพยาบาลจริยธรรมรวมแพทย์กระบี่'
        />
      </Helmet>

      <Layout>
        <div className='package'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/'>หน้าหลัก</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: '#666' }}>
              ร่วมเป็นทีมเดียวกัน
            </Breadcrumb.Item>
          </Breadcrumb>

          <div className='packages__content'>
            <h2 className='package__title border-gradient border-gradient-gold only-bottom'>
              ร่วมเป็นทีมเดียวกัน
            </h2>

            <div className='package__wrap'>
              {jobs.map((item, index) => (
                <Card
                  key={index}
                  hoverable
                  style={{ width: '100%' }}
                  cover={
                    <img
                      alt='ร่วมเป็นทีมเดียวกัน'
                      width='auto'
                      height='auto'
                      src={item.JOB_IMG}
                    />
                  }
                  className='news__card'
                  onClick={() => navigate(`/jobs/${item.JOB_ID}`)}
                >
                  <h4>{item.JOB_TITLE}</h4>
                  <div className='other-price'>
                    <UserOutlined
                      style={{ color: '#006d68', marginRight: 10 }}
                    />
                    <h5>จำนวน {item.JOB_QUANTITY_REQUIRED} อัตรา</h5>
                  </div>
                </Card>
              ))}
            </div>

            {/* <Pagination defaultCurrent={1} total={50} /> */}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Jobs
