import { menuItems } from '../MenuItems'
import MenuItems from './MenuItems'

const Navbar1 = () => {
  return (
    <nav>
      <ul className='menus'>
        {menuItems.map((menu, index) => {
          const depthLevel = 0
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />
        })}
      </ul>
    </nav>
  )
}

export default Navbar1
