import React from 'react'

const NotFound = () => {
  return (
    <div className='notfound'>
      <h1>404</h1>
      <h2>ERROR</h2>
      <h3>Page Not Found</h3>
    </div>
  )
}

export default NotFound
