import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Banner from './components/home/Banner'
import ImageNav from './components/home/ImageNav'
import News from './components/home/News'
import OurServices from './components/home/OurServices'
import Packages from './components/home/Packages'
import Layout from './components/Layout'
import { Helmet } from 'react-helmet-async'

const defaultBannerImage = [
  {
    BANNER_IMAGE: require('./images/banner/banner1.jpeg')
  },
  {
    BANNER_IMAGE: require('./images/banner/banner2.jpeg')
  }
]

const Home = () => {
  const [bannerImage, setBannerImages] = useState(defaultBannerImage)
  const [packages, setPackages] = useState([])
  const [news, setNews] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/home`)
      .then(async response => {
        const { code, item, message } = await response.data

        if (code === 10) {
          const { banner, packages, news } = await item

          setBannerImages(banner)
          setPackages(packages)
          setNews(news)
        } else {
          console.log(message)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>โรงพยาบาลจริยธรรมรวมแพทย์กระบี่ | Jariyatham Ruampat Krabi</title>
        <meta name='description' content='โรงพยาบาลจริยธรรมรวมแพทย์กระบี่ โรงพยาบาลชั้นนำในจังหวัดกระบี่ บริการด้วยแพทย์มากประสบการณ์ ทีมงานผู้เชี่ยวชาญพร้อมเทคโนโลยี เครื่องมือทันสมัย ด้วยมาตรฐานระดับโลก' />
      </Helmet>

      <Layout>
        <Banner
          bannerImage={bannerImage.length > 0 ? bannerImage : defaultBannerImage}
        />
        <OurServices />
        <Packages packages={packages} />
        <News news={news} />
        <ImageNav />
      </Layout>
    </>
  )
}

export default Home
