export const executives = [
  {
    id: 1,
    name: 'ดร.ศิดดิก ลาลีวัน',
    position: 'ผู้อำนวยการ',
    image: 'board1.jpeg'
  },
  {
    id: 2,
    name: 'นางสาวหุสนา ใบกาเด็ม',
    position: 'รองผู้อำนวยการ',
    image: 'board2.jpeg'
  },
  {
    id: 3,
    name: 'นางสาวดวงธิดา ผิวดำ',
    position: 'รองผู้อำนวยการ',
    image: 'board3.jpeg'
  },
  {
    id: 4,
    name: 'นายแพทย์พันธ์เทพ ฉิมหาด',
    position: 'แพทย์ทั่วไป',
    image: 'board4.jpg'
  },
  {
    id: 5,
    name: 'แพทย์หญิงพรรณพร เถาว์กลอย',
    position: 'แพทย์ทั่วไป',
    image: 'board5.jpeg'
  }
]
