import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import axios from 'axios'
import ServiceLayout from '../components/layouts/ServiceLayout'
import Loading from '../Loading'

const clinicId = 6

const AnnualHealthCheck = () => {
  const [clinic, setClinic] = useState(null)

  const getData = () => {
    return sessionStorage.getItem('clinics')
  }

  const fetchData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/clinic`)
      .then(async response => {
        const { code, item, message } = await response.data

        if (code === 10) {
          const thisClinic = item.find((element => element.CLINIC_ID === clinicId))
          const dataStr = JSON.stringify(item)

          setClinic(thisClinic)
          sessionStorage.setItem('clinics', dataStr)
        } else {
          console.log(message)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    const clinic = getData()

    if (!clinic) {
      fetchData()
    } else {
      const clinicArr = JSON.parse(clinic)
      const thisClinic = clinicArr.find((element => element.CLINIC_ID === clinicId))

      setClinic(thisClinic)
    }
  }, [])

  if (!clinic) {
    return <Loading />
  } else {
    return (
      <ServiceLayout
        label={clinic.CLINIC_NAME}
        img={clinic.CLINIC_IMG}
      >
        {
          clinic.CLINIC_DETAIL !== null ? (
            <p>{clinic.CLINIC_DETAIL}</p>
          ) : null
        }

        {/* <Skeleton active title={false} paragraph={{ rows: 5 }} /> */}
      </ServiceLayout>
    )
  }
}

export default AnnualHealthCheck
