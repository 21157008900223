import Layout from '../Layout'
import { Breadcrumb, Image } from 'antd'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const ServiceLayout = ({ children, label, img }) => {
  return (
    <>
      <Helmet>
        <title>{label} | โรงพยาบาลจริยธรรมรวมแพทย์กระบี่</title>
        <meta name='description' content={`${label} | คลินิกและศูนย์รัษา จาก โรงพยาบาลจริยธรรมรวมแพทย์กระบี่`} />
      </Helmet>

      <Layout>
        <div className='services'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/'>หน้าหลัก</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: '#666' }}>{label}</Breadcrumb.Item>
          </Breadcrumb>

          <div className='services__content'>
            <h1 className='border-gradient border-gradient-gold only-bottom'>{label}</h1>
            <div className='services__banner'>
              <Image width={'100%'} height='auto' alt={label} src={img} preview={false} />
            </div>

            <>{children}</>

            <div className='hashtag'>
              <p>#เราจะดูแลกัน</p>
              <p>#LikefamilyWecare</p>
            </div>
          </div>

          <div className='other'>
            <h3 className='other__title'>บริการอื่น ๆ</h3>
            <div className='underline'></div>

            <div className='services__container services__container-full'>
              <Link to={'/general'}>
                <div className='home__services-item'>
                  <img
                    src={require('../../images/services/n-service1.png')}
                    alt='general-service'
                    className='service__image'
                    width='auto'
                    height='auto'
                  />
                  <h3>คลินิกรักษาโรคทั่วไป</h3>
                </div>
              </Link>

              <Link to={'/kids'}>
                <div className='home__services-item'>
                  <img
                    src={require('../../images/services/n-service2.png')}
                    alt='kids-service'
                    className='service__image'
                    width='auto'
                    height='auto'
                  />
                  <h3>คลินิกเด็ก</h3>
                </div>
              </Link>

              <Link to={'/circumcision'}>
                <div className='home__services-item'>
                  <img
                    src={require('../../images/services/n-service3.png')}
                    alt='circumcision-service'
                    className='service__image'
                    width='auto'
                    height='auto'
                  />
                  <h3>คลินิกขลิบอนามัย</h3>
                </div>
              </Link>

              <Link to={'/alternative-medicine'}>
                <div className='home__services-item'>
                  <img
                    src={require('../../images/services/n-service4.png')}
                    alt='alternative-medicine-service'
                    className='service__image'
                    width='auto'
                    height='auto'
                  />
                  <h3>คลินิกแพทย์แผนไทย</h3>
                </div>
              </Link>

              <Link to={'/physical-therapy'}>
                <div className='home__services-item'>
                  <img
                    src={require('../../images/services/n-service5.png')}
                    alt='physical-therapy-service'
                    className='service__image'
                    width='auto'
                    height='auto'
                  />
                  <h3>คลินิกกายภาพบำบัด</h3>
                </div>
              </Link>

              <Link to={'/annual-health-check'}>
                <div className='home__services-item'>
                  <img
                    src={require('../../images/services/n-service6.png')}
                    alt='annual-health-check-service'
                    className='service__image'
                    width='auto'
                    height='auto'
                  />
                  <h3>ตรวจสุขภาพประจำปี</h3>
                </div>
              </Link>

              <Link to={'/hemodialysis-unit'}>
                <div className='home__services-item'>
                  <img
                    src={require('../../images/services/n-service7.png')}
                    alt='hemodialysis-unit-service'
                    className='service__image'
                    width='auto'
                    height='auto'
                  />
                  <h3>หน่วยไตเทียม</h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ServiceLayout
