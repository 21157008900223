import './App.css'
import Home from './Home'
import PackageDetail from './packages/PackageDetail'
import Packages from './packages/Packages'
import News from './news/News'
import Jobs from './jobs/Jobs'
import JobDetail from './jobs/JobDetail'
import NewsDetail from './news/NewsDetail'
import Executives from './executives/Executives'
import About from './about/About'
import { ConfigProvider } from 'antd'
import { Route, Routes } from 'react-router-dom'
import NotFound from './NotFound'
import Children from './services/Children'
import ThaiTraditional from './services/ThaiTraditional'
import Contact from './Contact2'
import PhysicalTherapy from './services/PhysicalTherapy'
import Circumcision from './services/Circumcision'
import GeneralClinic from './services/GeneralClinic'
import AnnualHealthCheck from './services/AnnualHealthCheck'
import HemodialysisUnit from './services/HemodialysisUnit'
import Result from './Result'

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#71a187',
          fontFamily: 'Noto Sans Thai'
        }
      }}
    >
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/general' element={<GeneralClinic />} />
        <Route path='/kids' element={<Children />} />
        <Route path='/circumcision' element={<Circumcision />} />
        <Route path='/alternative-medicine' element={<ThaiTraditional />} />
        <Route path='/physical-therapy' element={<PhysicalTherapy />} />
        <Route path='/annual-health-check' element={<AnnualHealthCheck />} />
        <Route path='/hemodialysis-unit' element={<HemodialysisUnit />} />
        <Route path='/about' element={<About />} />
        <Route path='/packages' element={<Packages />} />
        <Route path='/packages/:id' element={<PackageDetail />} />
        <Route path='/news' element={<News />} />
        <Route path='/news/:id' element={<NewsDetail />} />
        <Route path='/jobs' element={<Jobs />} />
        <Route path='/jobs/:id' element={<JobDetail />} />
        <Route path='/executives/:id' element={<Executives />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/result' element={<Result />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </ConfigProvider>
  )
}

export default App
