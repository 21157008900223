import React from 'react'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'
import Layout from './components/Layout'

const ResultPage = () => {
    const navigate = useNavigate()

    return (
        <Layout>
            <Result
                status='success'
                title='สำเร็จ!'
                subTitle='ระบบได้บันทึกคำถาม/ข้อเสนอแนะของท่านแล้ว โปรดรอการติดต่อกลับทาง SMS หรือ Email ที่ลงทะเบียนไว้หลังตรวจสอบข้อมูลเสร็จสิ้น'
                extra={[
                    <Button
                        type='primary'
                        key='console'
                        onClick={() => navigate('/')}
                    >
                        กลับสู่หน้าหลัก
                    </Button>
                ]}
            />
        </Layout>
    )
}

export default ResultPage
