import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'

const ImageNav = () => {
  return (
    <div className='iamgenav'>
      <div className='imagenav__image'>
        <img
          src={require('../../images/doctor.jpg')}
          alt='พบกับแพทย์ผู้เชี่ยวชาญ'
          className='imagenav__image-img'
          width='auto'
          height='auto'
        />

        <div className='imagenav__content'>
          <h3>พบกับแพทย์ผู้เชี่ยวชาญ</h3>

          <div className='imagenav__content-button'>
            <Link to='/#'>ค้นหาแพทย์</Link>
          </div>
        </div>
      </div>

      <div className='imagenav__image'>
        <img
          src={require('../../images/hospital.jpg')}
          alt='วิสัยทัศน์ และพันธกิจ'
          className='imagenav__image-img'
          width='auto'
          height='auto'
        />

        <div className='imagenav__content'>
          <h3>วิสัยทัศน์ และพันธกิจ</h3>

          <div className='imagenav__content-button'>
            <Link to='/about'>เกี่ยวกับเรา</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageNav
