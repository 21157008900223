import React, { useState } from 'react'
import { Breadcrumb } from 'antd'
import Layout from '../components/Layout'
import './executives.css'
import { Link, useParams } from 'react-router-dom'
import ExecutivesBody from '../components/ExecutivesBody'
import ExecutivesBody2 from '../components/ExecutivesBody2'
import Rewards from '../components/Rewards'
import { FaTrophy } from 'react-icons/fa'
import { BsPersonFill } from 'react-icons/bs'
import { Helmet } from 'react-helmet-async'

const Executives = () => {
  const { id } = useParams()

  const [menu, setMenu] = useState(id)

  const onMenuChange = menu => {
    setMenu(menu)
  }

  return (
    <>
      <Helmet>
        <title>ผู้บริหารโรงพยาบาล | โรงพยาบาลจริยธรรมรวมแพทย์กระบี่</title>
        <meta name='description' content='โรงพยาบาลจริยธรรมรวมแพทย์กระบี่ โรงพยาบาลชั้นนำในจังหวัดกระบี่ บริการด้วยแพทย์มากประสบการณ์ ทีมงานผู้เชี่ยวชาญพร้อมเทคโนโลยี เครื่องมือทันสมัย ด้วยมาตรฐานระดับโลก' />
      </Helmet>

      <Layout>
        <div className='executives'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/'>หน้าหลัก</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/about'>เกี่ยวกับโรงพยาบาล</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: '#666' }}>{menu}</Breadcrumb.Item>
          </Breadcrumb>

          <div className='executives__content'>
            <div className='executives__title'>
              <h1 className='border-gradient border-gradient-gold only-bottom'>{menu}</h1>
            </div>

            <div className='executives__body'>
              {/* <div className='executives__menus'>
              <div
                className='executives__menus-item'
                onClick={() => onMenuChange('ผู้บริหารโรงพยาบาล')}
              >
                <BsPersonFill style={{ color: '#006d68' }} />
                <span
                  style={{
                    color: menu === 'ผู้บริหารโรงพยาบาล' ? '#006d68' : '#555'
                  }}
                >
                  ผู้บริหารโรงพยาบาล
                </span>
              </div>

              <div
                className='executives__menus-item'
                onClick={() => onMenuChange('รางวัลและการรับรอง')}
              >
                <FaTrophy style={{ color: '#006d68' }} />
                <span
                  style={{
                    color: menu === 'รางวัลและการรับรอง' ? '#006d68' : '#555'
                  }}
                >
                  รางวัลและการรับรอง
                </span>
              </div>
            </div> */}

              {menu === 'ผู้บริหารโรงพยาบาล' ? <ExecutivesBody2 /> : <Rewards />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Executives
