import React from 'react'
import { Card } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'

const OtherNews = ({ news }) => {
  const navigate = useNavigate()

  return (
    <div className='other'>
      <h3 className='other__title'>ข่าวสารและกิจกรรมล่าสุด</h3>

      <div className='underline'></div>

      <div className='other__container'>
        {news.map((item, index) => {
          let image = item.NEW_IMG.slice(0, 5) === 'https' ? item.NEW_IMG : process.env.REACT_APP_COVER_PATH + item.NEW_IMG

          return (
            index < 4 && (
              <Card
                key={index}
                hoverable
                style={{ width: '100%' }}
                cover={<img alt='ข่าวสารและกิจกรรม' width='auto' height='auto' src={image} />}
                className='news__card'
                onClick={() => navigate(`/news/${item.NEW_ID}`)}
              >
                <h4>{item.NEW_TITLE}</h4>
              </Card>
            )
          )
        })}
      </div>

      <div className='see-all'>
        <div>
          <Link to='/news'>ดูทั้งหมด</Link>
          <RightOutlined style={{ color: '#006d68', marginLeft: 10 }} />
        </div>
      </div>
    </div>
  )
}

export default OtherNews
