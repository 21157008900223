import React from 'react'
import '../executives/executives.css'
import { executives } from '../data/executives'

const ExecutivesBody2 = () => {
  return (
    <div className='executives__profile'>
      {
        executives.map((item, index) => (
          <div key={index} className='executives__card-circle'>
            <img
              src={require(`../images/boards/${item.image}`)}
              width='auto'
              height='auto'
              alt='ผู้บริหารโรงพยาบาล'
              className='executives__profile-image-circle'
            />
            <h3 className='executives__name'>{item.name}</h3>
            <h5 className='executives__position'>{item.position}</h5>
          </div>
        ))
      }
    </div>
  )
}

export default ExecutivesBody2
