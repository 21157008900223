import React from 'react'
import { Image, Skeleton } from 'antd'
import img1 from '../images/award/award1-2.jpg'
import img2 from '../images/award/award2-2.jpg'

const Rewards = () => {
  return (
    <div className='award'>
      <h3 className='award__title'>
        Joint Commission International (JCI) (sample)
      </h3>

      <div className='award__img'>
        <Image width={'100%'} src={img1} preview={false} />
      </div>

      <div className='award__detail'>
        <Skeleton active title={false} paragraph={{ rows: 5 }} />
      </div>

      <h3 className='award__title'>
        World's Best Smart Hospitals 2021 (sample)
      </h3>

      <div className='award__img'>
        <Image width={'100%'} src={img2} preview={false} />
      </div>

      <div className='award__detail'>
        <Skeleton active title={false} paragraph={{ rows: 5 }} />
      </div>
    </div>
  )
}

export default Rewards
