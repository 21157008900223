import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Layout from '../components/Layout'
import { Breadcrumb, Card } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const News = () => {
  const navigate = useNavigate()

  const [news, setNews] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/news`)
      .then(async response => {
        const { code, item, message } = await response.data

        if (code === 10) {
          setNews(item)
        } else {
          console.log(message)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>ข่าวสารและกิจกรรม | โรงพยาบาลจริยธรรมรวมแพทย์กระบี่</title>
        <meta name='description' content='ความเคลื่อนไหว ข่าวสาร กิจกรรม จาก โรงพยาบาลจริยธรรมรวมแพทย์กระบี่' />
      </Helmet>

      <Layout>
        <div className='package'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/'>หน้าหลัก</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: '#666' }}>
              ข่าวสารและกิจกรรม
            </Breadcrumb.Item>
          </Breadcrumb>

          <div className='packages__content'>
            <h1 className='package__title border-gradient border-gradient-gold only-bottom'>
              ข่าวสารและกิจกรรม
            </h1>

            <div className='package__wrap'>
              {news.map((item, index) => (
                <Card
                  key={index}
                  hoverable
                  style={{ width: '100%' }}
                  cover={
                    <img
                      alt='news_image'
                      width='auto'
                      height='auto'
                      src={
                        item.NEW_IMG.slice(0, 5) === 'https'
                          ? item.NEW_IMG
                          : process.env.REACT_APP_COVER_PATH + item.NEW_IMG
                      }
                    />
                  }
                  className='news__card'
                  onClick={() => navigate(`/news/${item.NEW_ID}`)}
                >
                  <h4>{item.NEW_TITLE}</h4>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default News
