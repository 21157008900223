import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Layout from '../components/Layout'
import { Breadcrumb, Card, Pagination } from 'antd'
import { TagOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const Packages = () => {
  const navigate = useNavigate()

  const [packages, setPackages] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/package`)
      .then(async response => {
        const { code, item, message } = await response.data

        if (code === 10) {
          setPackages(item)
        } else {
          console.log(message)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>แพ็กเกจและโปรโมชั่น | โรงพยาบาลจริยธรรมรวมแพทย์กระบี่</title>
        <meta name='description' content='รวมโปรแกรมแพ็กเกจสุขภาพ จาก โรงพยาบาลจริยธรรมรวมแพทย์กระบี่' />
      </Helmet>

      <Layout>
        <div className='package'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/'>หน้าหลัก</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: '#666' }}>
              แพ็กเกจโปรโมชั่น
            </Breadcrumb.Item>
          </Breadcrumb>

          <div className='packages__content'>
            <h1 className='package__title border-gradient border-gradient-gold only-bottom'>
              แพ็กเกจและโปรโมชั่น
            </h1>

            <div className='package__wrap'>
              {packages.map((item, index) => (
                <Card
                  key={index}
                  hoverable
                  style={{ width: '100%' }}
                  cover={<img alt='package_image' width='auto' height='auto' src={item.package_img} />}
                  className='package__card'
                  onClick={() => navigate(`/packages/${item.package_id}`)}
                >
                  <h4>{item.package_title}</h4>

                  <div className='other-price'>
                    <TagOutlined style={{ color: '#006d68', marginRight: 10 }} />
                    <h5>{item.package_price} บาท</h5>
                  </div>

                  {item.package_date_end && (
                    <div className='other-price'>
                      <ClockCircleOutlined
                        style={{ color: '#006d68', marginRight: 10 }}
                      />
                      <h5>สิ้นสุด {item.package_date_end}</h5>
                    </div>
                  )}
                </Card>
              ))}
            </div>

            {/* <Pagination defaultCurrent={1} total={50} /> */}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Packages
