import React from 'react'
import './home.css'
import { Card } from 'antd'
import { Link, useNavigate } from 'react-router-dom'

const News = ({ news }) => {
  const navigate = useNavigate()

  return (
    <div className='home__news'>
      <h1 className='border-gradient border-gradient-gold only-bottom'>
        ข่าวสารและกิจกรรม
      </h1>

      <div className='home__news-container'>
        {news.map((item, index) =>
          index < 3 ? (
            <Card
              key={index}
              hoverable
              cover={
                <img
                  alt='news_image'
                  width='auto'
                  height='auto'
                  src={
                    item.NEW_IMG.slice(0, 5) === 'https'
                      ? item.NEW_IMG
                      : process.env.REACT_APP_COVER_PATH + item.NEW_IMG
                  }
                />
              }
              className='news__card'
              onClick={() => navigate(`/news/${item.NEW_ID}`)}
              style={{ padding: 0 }}
            >
              <h4>{item.NEW_TITLE}</h4>
              {/* <p>{item.NEW_DETAIL}</p> */}
            </Card>
          ) : null
        )}
      </div>

      <div className='package__button'>
        <Link to='/news'>ดูข่าวสารทั้งหมด</Link>
      </div>
    </div>
  )
}

export default News
