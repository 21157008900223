import React, { useState } from 'react'
import Layout from './components/Layout'
import { Breadcrumb, Button, Select, Input, Radio } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'
import { Helmet } from 'react-helmet-async'
import Iframe from 'react-iframe'

const Option = Select.Option;
const { TextArea } = Input;
const RadioGroup = Radio.Group;

function Contact2() {
    const navigate = useNavigate()

    const [selectValue, setSelectValue] = useState(null)
    const [detail, setDetail] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [email, setEmail] = useState(null)
    const [radioValue, setRadioValue] = useState(null)
    const [isError, setIsError] = useState(false)

    const handleSelectChange = (value) => {
        setSelectValue(value)
    }

    const onRadioChange = (e) => {
        console.log('radio checked', e.target.value)
        setRadioValue(e.target.value)
    }

    const onSubmit = () => {
        if (!selectValue || !detail || !firstName || !lastName || !phoneNumber || !email) {
            setIsError(true)
        } else {
            setIsError(false)
            navigate('/result')
        }
    }

    return (
        <>
            <Helmet>
                <title>ติดต่อเรา | โรงพยาบาลจริยธรรมรวมแพทย์กระบี่</title>
                <meta name='description' content='โรงพยาบาลจริยธรรมรวมแพทย์กระบี่ โรงพยาบาลชั้นนำในจังหวัดกระบี่ บริการด้วยแพทย์มากประสบการณ์ ทีมงานผู้เชี่ยวชาญพร้อมเทคโนโลยี เครื่องมือทันสมัย ด้วยมาตรฐานระดับโลก' />
            </Helmet>

            <Layout>
                <div className='contact'>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to='/'>หน้าหลัก</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item style={{ color: '#666' }}>ติดต่อเรา</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className='contact__container'>
                        <div className='contact__left'>
                            <h1 className='border-gradient border-gradient-gold only-bottom'>ติดต่อเรา</h1>

                            <Iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.005370306842!2d98.9081636!3d8.1009332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3051946b596c0455%3A0x5436fd677772edfe!2z4LmC4Lij4LiH4Lie4Lii4Liy4Lia4Liy4Lil4LiI4Lij4Li04Lii4LiY4Lij4Lij4Lih4Lij4Lin4Lih4LmB4Lie4LiX4Lii4LmM4LiB4Lij4Liw4Lia4Li14LmI!5e0!3m2!1sth!2sth!4v1714451635802!5m2!1sth!2sth"
                                className='contact__left-iframe'
                            />

                            <h3>โรงพยาบาลจริยธรรมรวมแพทย์กระบี่</h3>

                            <div className='contact__item'>
                                <FaMapMarkerAlt style={{ color: '#9299A0' }} />
                                <p>529 ถนน อุตรกิจ ตำบล ปากน้ำ อำเภอเมืองกระบี่ กระบี่ 81000 ประเทศไทย</p>
                            </div>
                            <div className='contact__item'>
                                <FaPhoneAlt style={{ color: '#9299A0' }} />
                                <p>075 664 455</p>
                            </div>
                            <div className='contact__item'>
                                <FaEnvelope style={{ color: '#9299A0' }} />
                                <p>jrh_2013@hotmail.com</p>
                            </div>
                        </div>

                        <div className='contact__right'>
                            <h3>ฝากข้อความ</h3>

                            <div className='contact__form'>
                                <div className='contact__formItem'>
                                    <p className={isError ? 'text__error' : ''}>เรื่องที่ต้องการติดต่อ *</p>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder='กรุณาระบุ'
                                        optionFilterProp='children'
                                        onChange={handleSelectChange}
                                        // onFocus={handleFocus}
                                        // onBlur={handleBlur}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        <Option value='1'>คำถามทั่วไป</Option>
                                        <Option value='2'>ข้อเสนอแนะ</Option>
                                    </Select>
                                </div>

                                <div className='contact__formItem'>
                                    <p className={isError ? 'text__error' : ''}>รายละเอียด *</p>
                                    <TextArea rows={3} placeholder='กรุณากรอกข้อมูล' onChange={(e) => setDetail(e.target.value)} />
                                </div>

                                <div className='contact__formItem-twocols'>
                                    <div className='contact__formItem'>
                                        <p className={isError ? 'text__error' : ''}>ชื่อจริง *</p>
                                        <Input onChange={(e) => setFirstName(e.target.value)} />
                                    </div>
                                    <div className='contact__formItem'>
                                        <p className={isError ? 'text__error' : ''}>นามสกุล *</p>
                                        <Input onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                </div>

                                <div className='contact__formItem-twocols'>
                                    <div className='contact__formItem'>
                                        <p className={isError ? 'text__error' : ''}>เบอร์โทรศัพท์ *</p>
                                        <Input onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </div>
                                    <div className='contact__formItem'>
                                        <p className={isError ? 'text__error' : ''}>อีเมล *</p>
                                        <Input onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>

                                <div className='contact__formItem'>
                                    <p>ช่องทางที่สะดวกให้ติดต่อกลับ</p>
                                    <RadioGroup onChange={onRadioChange} value={radioValue}>
                                        <Radio value={1}>โทรศัพท์</Radio>
                                        <Radio value={2}>อีเมล</Radio>
                                    </RadioGroup>
                                </div>

                                <div className='contact__formItem'>
                                    <Button className='contact__formItem-btn' type='primary' onClick={onSubmit}>ส่งข้อมูล</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Contact2
