import React from 'react'
import { Card } from 'antd'
import {
  TagOutlined,
  ClockCircleOutlined,
  RightOutlined
} from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'

const OtherPackages = ({ packages }) => {
  const navigate = useNavigate()
  return (
    <div className='other'>
      <h3 className='other__title'>คุณอาจสนใจ</h3>

      <div className='underline'></div>

      <div className='other__container'>
        {packages.map((item, index) => (
          index < 4 && (
            <Card
              key={index}
              hoverable
              style={{ width: '100%' }}
              cover={<img alt='แพ็กเกจโปรโมชัน' width='auto' height='auto' src={item.package_img} />}
              className='package__card'
              onClick={() => navigate(`/packages/${item.package_id}`)}
            >
              <h4>{item.package_title}</h4>

              <div className='other-price'>
                <TagOutlined style={{ color: '#006d68', marginRight: 10 }} />
                <h5>{item.package_price} บาท</h5>
              </div>

              {
                !item.package_date_end ?
                  null :
                  <div className='other-price'>
                    <ClockCircleOutlined
                      style={{ color: '#006d68', marginRight: 10 }}
                    />
                    <h5>สิ้นสุด {item.package_date_end}</h5>
                  </div>
              }
            </Card>
          )
        ))}
      </div>

      <div className='see-all'>
        <div>
          <Link to='/packages'>ดูทั้งหมด</Link>
          <RightOutlined style={{ color: '#006d68', marginLeft: 10 }} />
        </div>
      </div>
    </div>
  )
}

export default OtherPackages
