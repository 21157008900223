import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Layout from '../components/Layout'
import { Breadcrumb, Image } from 'antd'
import Loading from '../Loading'
import OtherNews from '../components/OtherNews'
import { Link, useParams } from 'react-router-dom'
import Masonry from 'react-responsive-masonry'
import { Helmet } from 'react-helmet-async'

const Gallery = ({ images }) => {
  return (
    <Masonry gutter='12px'>
      {images.map((image, i) => (
        <Image
          key={i}
          width={'100%'}
          height='auto'
          alt='ข่าวสารและกิจกรรม'
          src={image}
          style={{ borderRadius: 6 }}
        />
      ))}
    </Masonry>
  )
}

const NewsDetail = () => {
  const { id } = useParams()

  const [news, setNews] = useState(null)
  const [allNews, setAllNews] = useState([])
  const [otherNews, setOtherNews] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/news`)
      .then(async response => {
        const { code, item, message } = await response.data

        if (code === 10) {
          const thisNews = await item.find(news => news.NEW_ID === +id)

          setNews(thisNews)

          let otherNews = []

          item.forEach(news => {
            if (news.NEW_ID !== +id) {
              otherNews.push(news)
            }
          })

          setOtherNews(otherNews)
          setAllNews(item)
        } else {
          console.log(message)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const thisNews = allNews.find(news => news.NEW_ID === +id)

    setNews(thisNews)

    let otherNews = []

    allNews.forEach(news => {
      if (news.NEW_ID !== +id) {
        otherNews.push(news)
      }
    })

    setOtherNews(otherNews)
  }, [id])

  if (!news) {
    return <Loading />
  } else {
    return (
      <>
        <Helmet>
          <title>{news.NEW_TITLE} | โรงพยาบาลจริยธรรมรวมแพทย์กระบี่</title>
          <meta name='description' content={`${news.NEW_TITLE} | โรงพยาบาลจริยธรรมรวมแพทย์กระบี่`} />
        </Helmet>

        <Layout>
          <div className='newsdetail'>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to='/'>หน้าหลัก</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to='/news'>ข่าวสารและกิจกรรม</Link>
              </Breadcrumb.Item>
            </Breadcrumb>

            <div className='newsdetail__container'>
              <h1 className='news__title'>{news.NEW_TITLE}</h1>

              <div className='news__banner'>
                <Image
                  preview={false}
                  width={'100%'}
                  height='auto'
                  alt='ข่าวสารและกิจกรรม'
                  src={news.NEW_IMG.slice(0, 5) === 'https' ? news.NEW_IMG : process.env.REACT_APP_COVER_PATH + news.NEW_IMG} />
              </div>

              <div className='news__text' dangerouslySetInnerHTML={{ __html: news.NEW_DETAIL }} />

              <Gallery images={news.NEW_PIC_IMG} />

              <div className='news__text'>
                <p className='timestamp'>วันที่เผยแพร่ : {news.NEW_DATE}</p>
              </div>
            </div>

            {
              otherNews.length > 0 ? (
                <OtherNews news={otherNews} />
              ) : null
            }
          </div>
        </Layout>
      </>
    )
  }
}

export default NewsDetail
