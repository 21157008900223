import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { Breadcrumb, Image } from 'antd'
import { PhoneFilled } from '@ant-design/icons'
import OtherJobs from '../components/OtherJobs'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import { IoDocumentText } from 'react-icons/io5'
import Loading from '../Loading'

const PackageDetail = () => {
  const { id } = useParams()

  const [thisJob, setThisJob] = useState(null)
  const [allJob, setAllJob] = useState([])
  const [OtherJob, setOtherJob] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/jobs`)
      .then(async response => {
        const { code, item, message } = await response.data

        if (code === 10) {
          const thisJob = await item.find(job => job.JOB_ID === +id)

          setThisJob(thisJob)

          let otherJob = []

          item.forEach(job => {
            if (job.JOB_ID !== +id) {
              otherJob.push(job)
            }
          })

          setOtherJob(otherJob)
          setAllJob(item)
        } else {
          console.log(message)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const thisJob = allJob.find(news => news.NEW_ID === +id)

    setThisJob(thisJob)

    let otherJob = []

    allJob.forEach(job => {
      if (job.NEW_ID !== +id) {
        otherJob.push(job)
      }
    })

    setOtherJob(otherJob)
  }, [id])

  if (!thisJob) {
    return <Loading />
  } else {
    return (
      <>
        <Helmet>
          <title>{thisJob.JOB_TITLE} | โรงพยาบาลจริยธรรมรวมแพทย์กระบี่</title>
          <meta
            name='description'
            content='ร่วมเป็นทีมเดียวกัน กับ โรงพยาบาลจริยธรรมรวมแพทย์กระบี่'
          />
        </Helmet>

        <Layout>
          <div className='job'>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to='/'>หน้าหลัก</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to='/jobs'>ร่วมเป็นทีมเดียวกัน</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item style={{ color: '#666' }}>
                {thisJob.JOB_TITLE}
              </Breadcrumb.Item>
            </Breadcrumb>

            <div className='job__card'>
              <div className='job__image'>
                <Image
                  preview={false}
                  width='100%'
                  height='auto'
                  alt='ร่วมเป็นทีมเดียวกัน'
                  src={thisJob.JOB_IMG}
                />
              </div>

              <div className='job__detail'>
                <h1 className='packages__detail-title border-gradient border-gradient-gold only-bottom'>
                  {thisJob.JOB_TITLE}
                </h1>

                <p className='job__quantity'>
                  จำนวน <span>{thisJob.JOB_QUANTITY_REQUIRED}</span> อัตรา
                </p>

                <div
                  className='job__detail-text'
                  dangerouslySetInnerHTML={{ __html: thisJob.JOB_DETAIL }}
                />

                <div className='job__contact'>
                  <h5>ติดต่อสอบถามเพิ่มเติม</h5>

                  <div className='job__contact-btn'>
                    <a
                      href={thisJob.JOB_URL}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='job__contact-btn-btn line'
                    >
                      <IoDocumentText
                        style={{ fontSize: '16px', color: '#fff' }}
                      />
                      <h6>สมัครออนไลน์</h6>
                    </a>

                    <div className='job__contact-btn-btn tel'>
                      <PhoneFilled
                        style={{ fontSize: '16px', color: '#fff' }}
                      />
                      <h6>075 664455 ต่อ 323</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {OtherJob.length > 0 ? <OtherJobs jobs={OtherJob} /> : null}
          </div>
        </Layout>
      </>
    )
  }
}

export default PackageDetail
