export const menuItems = [
  {
    title: 'บริการโรงพยาบาล',
    submenu: [
      {
        title: 'แพ็กเกจโปรโมชั่น',
        path: '/packages'
      },
      // {
      //   title: 'บทความสุขภาพ',
      //   path: '/articles'
      // },
      {
        title: 'ข่าวสารและกิจกรรม',
        path: '/news'
      }
      // {
      //   title: 'ค้นหาแพทย์',
      //   path: '/doctor'
      // },
      // {
      //   title: 'ห้องพัก',
      //   path: '/rooms'
      // }
    ]
  },
  {
    title: 'คลินิกและศูนย์รักษา',
    submenu: [
      {
        title: 'คลินิกรักษาโรคทั่วไป',
        path: '/general'
      },
      {
        title: 'คลินิกเด็ก',
        path: '/kids'
      },
      {
        title: 'คลินิกขลิบอนามัย',
        path: '/circumcision'
      },
      {
        title: 'คลินิกแพทย์แผนไทย',
        path: '/alternative-medicine'
      },
      {
        title: 'คลินิกกายภาพบำบัด',
        path: '/physical-therapy'
      },
      {
        title: 'ตรวจสุขภาพประจำปี',
        path: '/annual-health-check'
      },
      {
        title: 'หน่วยไตเทียม',
        path: '/hemodialysis-unit'
      }
    ]
  },
  {
    title: 'เกี่ยวกับโรงพยาบาล',
    submenu: [
      {
        title: 'ประวัติโรงพยาบาล',
        path: '/about'
      },
      {
        title: 'วิสัยทัศน์ และพันธกิจ',
        path: '/about'
      },
      {
        title: 'ผู้บริหารโรงพยาบาล',
        path: '/executives/ผู้บริหารโรงพยาบาล'
      },
      // {
      //   title: 'คณะกรรมการ',
      //   path: '/executives/คณะกรรมการ'
      // },
      // {
      //   title: 'รางวัลและการรับรอง',
      //   path: '/executives/รางวัลและการรับรอง'
      // }
    ]
  },
  {
    title: 'ติดต่อเรา',
    submenu: [
      {
        title: 'ข้อมูลสำหรับติดต่อ',
        path: '/contact'
      },
      // {
      //   title: 'แผนที่โรงพยาบาล',
      //   path: 'https://www.google.com/maps/place/%E0%B9%82%E0%B8%A3%E0%B8%87%E0%B8%9E%E0%B8%A2%E0%B8%B2%E0%B8%9A%E0%B8%B2%E0%B8%A5%E0%B8%88%E0%B8%A3%E0%B8%B4%E0%B8%A2%E0%B8%98%E0%B8%A3%E0%B8%A3%E0%B8%A1%E0%B8%A3%E0%B8%A7%E0%B8%A1%E0%B9%81%E0%B8%9E%E0%B8%97%E0%B8%A2%E0%B9%8C%E0%B8%81%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%B5%E0%B9%88/@8.1009332,98.9055887,17z/data=!3m1!4b1!4m6!3m5!1s0x3051946b596c0455:0x5436fd677772edfe!8m2!3d8.1009332!4d98.9081636!16s%2Fg%2F1tsq86pc?entry=ttu'
      // },
      {
        title: 'ร่วมเป็นทีมเดียวกัน',
        path: '/jobs'
      }
    ]
  }
]
