import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Layout from '../components/Layout'
import { Breadcrumb, Image } from 'antd'
import { MessageFilled, PhoneFilled } from '@ant-design/icons'
import OtherPackages from '../components/OtherPackages'
import { Link, useParams } from 'react-router-dom'
import Loading from '../Loading'
import { Helmet } from 'react-helmet-async'

const PackageDetail = () => {
  const { id } = useParams()

  const [thisPackage, setThisPackage] = useState(null)
  const [allPackage, setAllPackage] = useState([])
  const [otherPackage, setOtherPackage] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/package`)
      .then(async response => {
        const { code, item, message } = await response.data

        if (code === 10) {
          const thisPackage = item.find(item => item.package_id === +id)

          setThisPackage(thisPackage)

          let otherPackage = []

          item.forEach(item => {
            if (item.package_id !== +id) {
              otherPackage.push(item)
            }
          })

          setOtherPackage(otherPackage)
          setAllPackage(item)
        } else {
          console.log(message)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const thisPackage = allPackage.find(item => item.package_id === +id)

    setThisPackage(thisPackage)

    let otherPackage = []

    allPackage.forEach(item => {
      if (item.package_id !== +id) {
        otherPackage.push(item)
      }
    })

    setOtherPackage(otherPackage)
  }, [id])

  if (!thisPackage) {
    return <Loading />
  } else {
    return (
      <>
        <Helmet>
          <title>{thisPackage.package_title} | โรงพยาบาลจริยธรรมรวมแพทย์กระบี่</title>
          <meta name='description' content='รวมโปรแกรมแพ็กเกจสุขภาพ จาก โรงพยาบาลจริยธรรมรวมแพทย์กระบี่' />
        </Helmet>

        <Layout>
          <div className='job'>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to='/'>หน้าหลัก</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to='/packages'>แพ็กเกจโปรโมชั่น</Link>
              </Breadcrumb.Item>
            </Breadcrumb>

            <div className='job__card'>
              <div className='job__image'>
                <Image preview={false} width='100%' height='auto' alt='แพ็กเกจโปรโมชัน' src={thisPackage.package_img} />
              </div>

              <div className='job__detail'>
                <h1 className='packages__detail-title border-gradient border-gradient-gold only-bottom'>{thisPackage.package_title}</h1>

                <div className='packages__detail-price'>
                  <p>ราคา</p>
                  <h5>{thisPackage.package_price} บาท</h5>
                </div>

                <div className='job__detail-text' dangerouslySetInnerHTML={{ __html: thisPackage.package_detail }} />

                <div className='job__contact'>
                  <h5>ติดต่อสอบถามเพิ่มเติม</h5>

                  <div className='job__contact-btn'>
                    <div className='job__contact-btn-btn line'>
                      <MessageFilled style={{ fontSize: '16px', color: '#fff' }} />
                      <h6>แชทผ่าน LINE</h6>
                    </div>

                    <div className='job__contact-btn-btn tel'>
                      <PhoneFilled style={{ fontSize: '16px', color: '#fff' }} />
                      <h6>075 664 455</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {
              otherPackage.length > 0 ? (
                <OtherPackages packages={otherPackage} />
              ) : null
            }
          </div>
        </Layout>
      </>
    )
  }
}

export default PackageDetail
