import React from 'react'
import { Carousel, Image } from 'antd'
import './home.css'

const Banner = ({ bannerImage }) => {
  return (
    <div className='home__banner'>
      <Carousel autoplay>
        {bannerImage.map((item, index) => (
          <Image alt='jariyatham ruampat krabi banner' className='home__banner-image' key={index} width={'100%'} height='auto' src={item.BANNER_IMAGE} preview={false} />
        ))}
      </Carousel>
    </div>
  )
}

export default Banner
