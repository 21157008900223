import React from 'react'
import './home.css'
import { Card } from 'antd'
import { TagOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'

const Packages = ({ packages }) => {
  const navigate = useNavigate()

  return (
    <div className='home__packages'>
      <h1 className='border-gradient border-gradient-gold only-bottom'>
        แพ็กแกจแนะนำ
      </h1>

      <div className='home__packages-container'>
        {packages.map((item, index) =>
          index < 3 ? (
            <Card
              className='package__card'
              key={item.package_id}
              hoverable
              cover={<img alt='package_image' width='auto' height='auto' src={item.package_img} />}
              onClick={() => navigate(`/packages/${item.package_id}`)}
            >
              <h4>{item.package_title}</h4>

              <div className='other-price'>
                <TagOutlined style={{ color: '#006d68', marginRight: 10 }} />
                <h5>{item.package_price} บาท</h5>
              </div>

              {
                !item.package_date_end ?
                  null :
                  <div className='other-price'>
                    <ClockCircleOutlined
                      style={{ color: '#006d68', marginRight: 10 }}
                    />
                    <h5>สิ้นสุด {item.package_date_end}</h5>
                  </div>
              }
            </Card>
          ) : null
        )}
      </div>

      <div className='package__button'>
        <Link to='/packages'>ดูแพ็กแกจทั้งหมด</Link>
      </div>
    </div>
  )
}

export default Packages
