import React from 'react'
import {
  FacebookFilled,
  TwitterSquareFilled,
  YoutubeFilled,
  InstagramFilled,
  MailFilled,
  EnvironmentFilled,
  PhoneFilled
} from '@ant-design/icons'
import './footer.css'
import { menuItems } from '../MenuItems'
import { Link, useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import Iframe from 'react-iframe'
import { MdAdminPanelSettings } from 'react-icons/md'
import { FaFacebook } from 'react-icons/fa'

function getItem (label, key, children) {
  return {
    key,
    children,
    label
  }
}

const items2 = menuItems.map((menuItem, index) =>
  getItem(
    menuItem.title,
    menuItem.title,
    menuItem.submenu.map((element, i) => getItem(element.title, element.title))
  )
)

function Footer () {
  const navigate = useNavigate()

  const onClick = e => {
    const thisMenu = menuItems.find(item => item.title === e.keyPath[1])
    const thisSubMenu = thisMenu.submenu.find(
      item => item.title === e.keyPath[0]
    )

    if (thisSubMenu.title === 'แผนที่โรงพยาบาล') {
      window.open(thisSubMenu.path, '_blank')
    } else {
      navigate(thisSubMenu.path)
    }
  }

  return (
    <>
      <div className='end'>
        <div className='underline'></div>
      </div>

      <footer>
        <div className='contact-card'>
          <div className='contact-item contact-item-border-left'>
            <PhoneFilled
              style={{ fontSize: '28px', color: '#fff', marginBottom: 16 }}
            />

            <div className='contact-item-tel'>
              <p className='contact-card-text'>โทรหาเรา</p>
              <p className='contact-card-text-tel'>075 664 455</p>
            </div>

            <p className='contact-card-text text-small'>
              พร้อมบริการทุกวัน 24 ชม.
            </p>
          </div>

          <div className='contact-item contact-item-border-left'>
            <MailFilled
              style={{ fontSize: '28px', color: '#fff', marginBottom: 16 }}
            />

            <div>
              <p className='contact-card-text'>ติดต่อสอบถาม</p>

              <Link to='/contact'>
                <button className='contact-card-text'>ติดต่อ</button>
              </Link>
            </div>
          </div>

          <div className='contact-item contact-item-border-left contact-item-border-right'>
            <EnvironmentFilled
              style={{ fontSize: '28px', color: '#fff', marginBottom: 16 }}
            />

            <div>
              <p className='contact-card-text'>ที่ตั้งโรงพยาบาล</p>
              <Link to='/contact'>
                <button className='contact-card-text'>ดูแผนที่</button>
              </Link>
            </div>
          </div>
        </div>

        <div className='social'>
          <a
            href='https://www.facebook.com/JRHKrabi'
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='social-item'>
              <FaFacebook style={{ fontSize: '20px', color: '#888' }} />
            </div>
          </a>

          <a
            href={process.env.REACT_APP_ADMIN_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='social-item'>
              <MdAdminPanelSettings
                style={{ fontSize: '22px', color: '#888' }}
              />
            </div>
          </a>

          <div className='social-item'>
            <YoutubeFilled style={{ fontSize: '18px', color: '#888' }} />
          </div>

          <div className='social-item'>
            <InstagramFilled style={{ fontSize: '18px', color: '#888' }} />
          </div>
        </div>

        <div className='footer-menus'>
          {menuItems?.map((menu, index) => (
            <div key={index} className='footer-menu-item'>
              <h4>{menu.title}</h4>

              <div className='underline'></div>

              <ul>
                {menu.submenu?.map((submenu, index) => (
                  <li className='footer-submenu' key={index}>
                    {submenu.title === 'แผนที่โรงพยาบาล' ? (
                      <a
                        href={submenu.path}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {submenu.title}
                      </a>
                    ) : (
                      <Link to={submenu.path}>{submenu.title}</Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className='footer-mobile-menu'>
          <Menu
            onClick={onClick}
            style={{ width: '100%', color: '#555' }}
            mode='inline'
            items={items2}
          />
        </div>

        <div className='footer__underline'></div>

        <div className='copyright'>
          <Iframe
            url='https://webapi.ruampatkrabi.com/admin/counter.php'
            className='iframe'
          />

          <div>
            <p className='copyright-text'>
              &copy; โรงพยาบาลจริยธรรมรวมแพทย์กระบี่
              ได้รับการรับรองมาตรฐานคุณภาพ (ISO 9001: 2015)
            </p>

            <p className='copyright-text'>
              529 ถนน อุตรกิจ ตำบล ปากน้ำ อำเภอเมืองกระบี่ กระบี่ 81000
              ประเทศไทย
            </p>
            {/* <p className='copyright-text'>
              หากท่านมีข้อคิดเห็นหรือปัญหาในการใช้เว็บไซต์ของเรา
            </p> */}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
