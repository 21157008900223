import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'

const OurServices = () => {
  return (
    <div className='home__services'>
      <h1 className='border-gradient border-gradient-gold only-bottom'>บริการของเรา</h1>

      <div className='home__services-container'>
        <Link to={'/general'}>
          <div className='home__services-item'>
            <img
              src={require('../../images/services/n-service1.png')}
              alt='general-service'
              className='service__image'
              width='auto'
              height='auto'
            />
            <h3>คลินิกรักษาโรคทั่วไป</h3>
          </div>
        </Link>

        <Link to={'/kids'}>
          <div className='home__services-item'>
            <img
              src={require('../../images/services/n-service2.png')}
              alt='kids-service'
              className='service__image'
              width='auto'
              height='auto'
            />
            <h3>คลินิกเด็ก</h3>
          </div>
        </Link>

        <Link to={'/circumcision'}>
          <div className='home__services-item'>
            <img
              src={require('../../images/services/n-service3.png')}
              alt='circumcision-service'
              className='service__image'
              width='auto'
              height='auto'
            />
            <h3>คลินิกขลิบอนามัย</h3>
          </div>
        </Link>

        <Link to={'/alternative-medicine'}>
          <div className='home__services-item'>
            <img
              src={require('../../images/services/n-service4.png')}
              alt='alternative-medicine-service'
              className='service__image'
              width='auto'
              height='auto'
            />
            <h3>คลินิกแพทย์แผนไทย</h3>
          </div>
        </Link>

        <Link to={'/physical-therapy'}>
          <div className='home__services-item'>
            <img
              src={require('../../images/services/n-service5.png')}
              alt='physical-therapy-service'
              className='service__image'
              width='auto'
              height='auto'
            />
            <h3>คลินิกกายภาพบำบัด</h3>
          </div>
        </Link>

        <Link to={'/annual-health-check'}>
          <div className='home__services-item'>
            <img
              src={require('../../images/services/n-service6.png')}
              alt='annual-health-check-service'
              className='service__image'
              width='auto'
              height='auto'
            />
            <h3>ตรวจสุขภาพประจำปี</h3>
          </div>
        </Link>

        <Link to={'/hemodialysis-unit'}>
          <div className='home__services-item'>
            <img
              src={require('../../images/services/n-service7.png')}
              alt='hemodialysis-unit-service'
              className='service__image'
              width='auto'
              height='auto'
            />
            <h3>หน่วยไตเทียม</h3>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default OurServices
