import React, { useState } from 'react'
import Footer from './Footer'
import Navbar1 from './Navbar1'
// import logo from '../images/logo-white.png'
import logo from '../images/logo-cream.jpg'
import { Link, useNavigate } from 'react-router-dom'
import { Watermark } from 'antd'
import { IoMdMenu, IoMdClose } from 'react-icons/io'
import { Menu } from 'antd'
import { menuItems } from '../MenuItems'

function getItem(label, key, children) {
  return {
    key,
    children,
    label
  }
}

const items2 = menuItems.map((menuItem, index) =>
  getItem(
    menuItem.title,
    menuItem.title,
    menuItem.submenu.map((element, i) => getItem(element.title, element.title))
  )
)

const Layout = ({ children }) => {
  const navigate = useNavigate()

  const [showDrawer, setShowDrawer] = useState(false)

  const onClick = e => {
    const thisMenu = menuItems.find(item => item.title === e.keyPath[1])
    const thisSubMenu = thisMenu.submenu.find(
      item => item.title === e.keyPath[0]
    )

    if (thisSubMenu.title === 'แผนที่โรงพยาบาล') {
      window.open(thisSubMenu.path, '_blank')
    } else {
      navigate(thisSubMenu.path)
    }
  }

  return (
    // <Watermark content='ตัวอย่าง'>
    <>
      <header>
        <div className='nav-area'>
          <Link to='/'>
            <img src={logo} alt='logo' className='logo-image' width='auto' height='auto' />
          </Link>
          <Navbar1 />
        </div>

        <div className='mobile-nav-area'>
          <Link to='/'>
            <img src={logo} alt='logo' className='logo-image' width='auto' height='auto' />
          </Link>

          <IoMdMenu className='nav-menu-icon' onClick={() => setShowDrawer(true)} />
        </div>

        <nav className={`mobile__nav ${showDrawer ? 'mobile__nav-show' : 'mobile__nav-hidden'}`}>
          <div className='mobile__nav-close'>
            <IoMdClose className='nav-menu-icon' onClick={() => setShowDrawer(false)} />
          </div>
          <div className='mobile-nav-menu'>
            <Menu
              onClick={onClick}
              style={{ width: '100%' }}
              mode='inline'
              items={items2}
            />
          </div>
        </nav>
      </header>

      {/* <Navbar /> */}

      <main>{children}</main>

      <Footer />
      {/* </Watermark> */}
    </>
  )
}

export default Layout
